import React, { useCallback, useEffect, useState } from 'react';
import ProtectBtn from '../components/protected/protectbtn';
import { useAuth } from '../context/authContext';

import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";

import {
  Bodies,
  Body,
  Composite,
  Engine,
  Events,
  IEventCollision,
  Render,
  Runner,
  World
} from 'matter-js'

// multiplierImages
import multiplier0dot3Img from '../assets/img/multipliers/multiplier0.3.png'
import multiplier0dot5Img from '../assets/img/multipliers/multiplier0.5.png'
import multiplier1dot5Img from '../assets/img/multipliers/multiplier1.5.png'
import multiplier1Img from '../assets/img/multipliers/multiplier1.png'
import multiplier10Img from '../assets/img/multipliers/multiplier10.png'
import multiplier110Img from '../assets/img/multipliers/multiplier110.png'
import multiplier15Img from '../assets/img/multipliers/multiplier15.png'
import multiplier18Img from '../assets/img/multipliers/multiplier18.png'
import multiplier2Img from '../assets/img/multipliers/multiplier2.png'
import multiplier25Img from '../assets/img/multipliers/multiplier25.png'
import multiplier3Img from '../assets/img/multipliers/multiplier3.png'
import multiplier33Img from '../assets/img/multipliers/multiplier33.png'
import multiplier41Img from '../assets/img/multipliers/multiplier41.png'
import multiplier5Img from '../assets/img/multipliers/multiplier5.png'
import multiplier88Img from '../assets/img/multipliers/multiplier88.png'
// multiplierSoundEffects
import multiplierBest from '../assets/sounds/multiplier-best.wav'
import multiplierGood from '../assets/sounds/multiplier-good.wav'
import multiplierLow from '../assets/sounds/multiplier-low.wav'
import multiplierRegular from '../assets/sounds/multiplier-regular.wav'
import ballAudio from '../assets/sounds/ball.wav'
import { mydb } from '../context/firebase';

const multipliers = {
  110: {
    label: 'block-110',
    sound: multiplierBest,
    img: multiplier110Img
  },
  88: {
    label: 'block-88',
    sound: multiplierBest,
    img: multiplier88Img
  },
  41: {
    label: 'block-41',
    sound: multiplierBest,
    img: multiplier41Img
  },
  33: {
    label: 'block-33',
    sound: multiplierBest,
    img: multiplier33Img
  },
  25: {
    label: 'block-25',
    sound: multiplierBest,
    img: multiplier25Img
  },
  18: {
    label: 'block-18',
    sound: multiplierGood,
    img: multiplier18Img
  },
  15: {
    label: 'block-15',
    sound: multiplierGood,
    img: multiplier15Img
  },
  10: {
    label: 'block-10',
    sound: multiplierGood,
    img: multiplier10Img
  },
  5: {
    label: 'block-5',
    sound: multiplierGood,
    img: multiplier5Img
  },
  3: {
    label: 'block-3',
    sound: multiplierRegular,
    img: multiplier3Img
  },
  2: {
    label: 'block-2',
    sound: multiplierRegular,
    img: multiplier2Img
  },
  1.5: {
    label: 'block-1.5',
    sound: multiplierRegular,
    img: multiplier1dot5Img
  },
  1: {
    label: 'block-1',
    sound: multiplierRegular,
    img: multiplier1Img
  },
  0.5: {
    label: 'block-0.5',
    sound: multiplierLow,
    img: multiplier0dot5Img
  },
  0.3: {
    label: 'block-0.3',
    sound: multiplierLow,
    img: multiplier0dot3Img
  }
}

function getMultiplier(value) {
  return multipliers[value]
}

const multiplyBlocks16Lines = [
  getMultiplier(110),
  getMultiplier(41),
  getMultiplier(10),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(10),
  getMultiplier(41),
  getMultiplier(110)
]

const multiplierSounds = {
  110: multiplierBest,
  88: multiplierBest,
  41: multiplierBest,
  33: multiplierBest,
  25: multiplierBest,
  18: multiplierGood,
  15: multiplierGood,
  10: multiplierGood,
  5: multiplierGood,
  3: multiplierRegular,
  2: multiplierRegular,
  1.5: multiplierRegular,
  1: multiplierRegular,
  0.5: multiplierLow,
  0.3: multiplierLow
}

function getMultiplierSound(value) {
  return multiplierSounds[value]
}

function random(min, max) {
  const random = Math.random()
  min = Math.round(min)
  max = Math.floor(max)

  return random * (max - min) + min
}

function Index() {

  const engine = Engine.create()
  const [lines, setLines] = useState(16)

  const worldWidth = 390
  const worldHeight = 390

  useEffect(() => {
    engine.gravity.y = 1.0
    const element = document.getElementById('plinko')
    const render = Render.create({
      element: element,
      bounds: {
        max: {
          y: worldHeight,
          x: worldWidth
        },
        min: {
          y: 0,
          x: 0
        }
      },
      options: {
        background: 'transparent',
        hasBounds: true,
        width: worldWidth,
        height: worldHeight,
        wireframes: false
      },
      engine
    })
    const runner = Runner.create()
    Runner.run(runner, engine)
    Render.run(render)
    return () => {
      World.clear(engine.world, true)
      Engine.clear(engine)
      render.canvas.remove()
      render.textures = {}
    }
  }, [lines])

  const pins = []

  for (let l = 0; l < lines; l++) {
    const linePins = 3 + l
    const lineWidth = linePins * 20
    for (let i = 0; i < linePins; i++) {
      const pinX = worldWidth / 2 - lineWidth / 2 + i * 20 + 20 / 2

      const pinY = worldWidth / lines + l * 20 + 20

      const pin = Bodies.circle(pinX, pinY, 2, {
        label: `pin-${i}`,
        render: {
          fillStyle: '#fff'
        },
        isStatic: true
      })
      pins.push(pin)
    }
  }

  function addInGameBall() {
    if (inGameBallsCount > 15) return
    incrementInGameBallsCount()
  }

  function removeInGameBall() {
    decrementInGameBallsCount()
  }

  const addBall = useCallback(
    (ballValue) => {
      // addInGameBall()
      const ballSound = new Audio(ballAudio)
      ballSound.volume = 0.2
      ballSound.currentTime = 0
      ballSound.play()

      for (let xx = 0; xx < 1; xx++) {
        const minBallX = worldWidth / 2 - 2 * 3 + 20
        const maxBallX = worldWidth / 2 - 2 * 3 - 20 + 20 / 2
        const ballX = random(minBallX, maxBallX)

        const ball = Bodies.circle(ballX, 20, 5.7, {
          restitution: 1,
          friction: 0.6,
          label: `ball-${ballValue}`,
          ballX: ballX,
          id: new Date().getTime(),
          frictionAir: 0.05,
          collisionFilter: {
            group: -1
          },
          render: {
            // fillStyle: "url(/assets/img/favicon.png)"
            sprite: {
              texture: "/assets/img/favicon.png",
              xScale: 0.03,
              yScale: 0.03
            }
          },
          isStatic: false
        })
        Composite.add(engine.world, ball)
      }
    },
    [lines]
  )

  const leftWall = Bodies.rectangle(
    worldWidth / 3 - 2 * 20 - 20,
    worldWidth / 2 - 2,
    worldWidth * 2,
    40,
    {
      angle: 90,
      render: {
        visible: false
      },
      isStatic: true
    }
  )
  const rightWall = Bodies.rectangle(
    worldWidth -
    2 * 20 -
    20 -
    20 / 2,
    worldWidth / 2 - 2,
    worldWidth * 2,
    40,
    {
      angle: -90,
      render: {
        visible: false
      },
      isStatic: true
    }
  )

  const multipliers = multiplyBlocks16Lines

  const multipliersBodies = []

  let lastMultiplierX = worldWidth / 2 - (20 / 2) * lines - 20

  multipliers.forEach(multiplier => {
    const blockSize = 20 // height and width
    const multiplierBody = Bodies.rectangle(
      lastMultiplierX + 20,
      worldWidth / lines + lines * 20 + 20,
      blockSize,
      blockSize,
      {
        label: multiplier.label,
        isStatic: true,
        render: {
          sprite: {
            xScale: 1,
            yScale: 1,
            texture: multiplier.img
          }
        }
      }
    )
    lastMultiplierX = multiplierBody.position.x
    multipliersBodies.push(multiplierBody)
  })

  Composite.add(engine.world, [
    ...pins,
    ...multipliersBodies,
    leftWall,
    rightWall
  ])

  function bet() {
    addBall(111)
  }

  async function onCollideWithMultiplier(ball, multiplier) {
    ball.collisionFilter.group = 2
    World.remove(engine.world, ball)
    // removeInGameBall()
    const ballValue = ball.label.split('-')[1]
    const multiplierValue = +multiplier.label.split('-')[1]

    // console.log(ball.ballX, multiplierValue)
    const multiplierSong = new Audio(getMultiplierSound(multiplierValue))
    multiplierSong.currentTime = 0
    multiplierSong.volume = 0.2
    multiplierSong.play()
    // setLastMultipliers(prev => [multiplierValue, prev[0], prev[1], prev[2]])

    if (+ballValue <= 0) return
    // await addDoc(collection(mydb, "result"), {
    //   x: Number(ball.ballX),
    //   s: multiplierValue
    // })
    // const newBalance = +ballValue * multiplierValue
    // await incrementCurrentBalance(newBalance)
  }

  async function onBodyCollision(event) {
    const pairs = event.pairs
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair
      if (bodyB.label.includes('ball') && bodyA.label.includes('block'))
        await onCollideWithMultiplier(bodyB, bodyA)
    }
  }

  Events.on(engine, 'collisionActive', onBodyCollision)

  return (
    <div>
      <div className="about__container container grid">
        <div className="about__data">
          <button className="button" onClick={bet}>Drop the ball</button>
        </div>

        <div className="about__data">
          <div id="plinko" />
        </div>
      </div>
    </div>
  );
}
export default Index