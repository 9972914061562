import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAa5OvL0WpvESvgRXzmfGiCw5NVA-m8kps",
    authDomain: "test-d9420.firebaseapp.com",
    projectId: "test-d9420",
    storageBucket: "test-d9420.appspot.com",
    messagingSenderId: "946186319251",
    appId: "1:946186319251:web:d35b27101680f3bcd9a466"
}

const app = initializeApp(firebaseConfig)
export const mydb = getFirestore(app)